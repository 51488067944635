<template>
    <div class="container">
        <div class="mobile-panel position-page">
            <CommonBlock title="Позиція партії"></CommonBlock>
        </div>
        <div class="wrapper">
            <section class="position">
                <header class="header">
                    <router-link to="/" class="header-logo">
                        <img src="../assets/images/logo-header.svg" alt="logo"/>
                    </router-link>
                </header>
                <Breadcrumb :items="[{id: 1, name: 'Позиція партії'}]" :dark-theme="true" class="breadcrumb1"/>
                <div class="page">
                    <div class="page-top">
                        <h3 class="page-title">Позиція партії</h3>
                    </div>

                    <div class="cards">
                        <router-link
                            :to="{name: 'voting'}"
                            class="cards-item position1"
                            v-if="showVotingList"
                        >
                            <span class="cards-item_img">
                              <img src="/img/pic_pd.778ecbb8.svg"/>
                            </span>
                            <h5 class="cards-item__title">Голосування</h5>
                        </router-link>
                        <router-link
                            v-for="card in cards"
                            :key="card.index"
                            :to="card.url"
                            class="cards-item position1"
                        >
                            <span class="cards-item_img">
                              <img :src="card.image"/>
                            </span>
                            <h5 class="cards-item__title">
                                {{ card.cardTitle }}
                            </h5>
                        </router-link>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import CommonBlock from "../components/CommonBlock";
import Breadcrumb from "../components/Breadcrumb";
import VotingService from "../services/VotingService";

export default {
    data() {
        return {
            cards: [],
            showVotingList: false
        };
    },
    methods:{
      async checkVoting(){
          let resp = await VotingService.getVotingList()
          if (resp?.data?.items.length){
              this.showVotingList = true
          }
      }
    },
    components: {
        CommonBlock,
        Breadcrumb
    },
    created() {
        axios.get('/api/position').then(resp => {
            this.cards = resp.data.map(item => {
                item.cardTitle = item.title;
                item.image = item.preview;
                item.url = '/position/' + item.id;
                return item;
            });
        });
        this.checkVoting()
    }
};
</script>


<style lang="less">
.position-page.mobile-panel {
    display: none;
    @media screen and (max-width: 992px) {
        display: block;
        .body{
            display: none!important;
        }
    }
}

</style>
<style scoped lang="less">
.container {
    background: #f2f2f2;
    min-height: 100vh;
    padding-bottom: 50px;

    @media screen and (max-width: 540px) {
        padding-bottom: 0;
    }
}

.wrapper {
    //max-width: 1920px;
    margin: 0 auto;
    background: #f2f2f2;
}

.header {
    width: 100%;
    padding-top: 80px;
}

.header-logo {
    width: 253px;
    height: 51px;
    margin-bottom: 22px;
    display: inline-block;
}

/* breadcrumb */
.breadcrumb {
    list-style: none;
    margin-bottom: 45px;
}
.breadcrumb1 {
    @media all and (max-width: 992px) {
        display: none!important;
    }
}

.breadcrumb-item {
    display: inline;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.112px;
    color: #1a2744;
    margin-right: 20px;
}

.breadcrumb-link {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.112px;
    color: #ffc900;
    text-decoration: none;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        width: 1px;
        height: 15px;
        background: #1a2744;
        bottom: 1px;
        right: -11px;
    }
}

.page {
    &-title {
        font-weight: bold;
        font-size: 60px;
        line-height: 80px;
        letter-spacing: -0.42px;
        color: #1a2744;
    }

    &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 53px;
    }
}

/* Study page */
.position {
    background: #f2f2f2;
    padding-left: 60px;
    padding-right: 60px;
}

.search-block {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
        height: 56px;
        width: 540px;
        border-radius: 15px;
        padding-left: 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #1a2744;
        outline: none;
        border: 1px solid #ffc900;
        margin-right: 28px;

        &::placeholder {
            color: #1a2744;
        }
    }

    button {
        width: 56px;
        height: 56px;
        background: #ffc900;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 24px 24px -14px rgba(249, 198, 35, 0.35);
    }
}

/* Cards */

.cards {
    max-width: 1920px;
    margin: 0 auto;
    display: grid;
    grid-gap: 20px;

    @media (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 900px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
    }

    &-item {
        /*max-width: 580px; */
        height: 512px;
        cursor: pointer;
        position: relative;
        background: #fff;
        padding: 40px 40px 40px 40px;
        transition: all 0.8s ease;

        display: flex;
        flex-direction: column;
        width: 100%;

        overflow: hidden;
        text-decoration: none;

        @media screen and (max-width: 1440px) {
            height: 450px;
        }

        @media screen and (max-width: 950px) {
            height: 282px;
        }
        @media (max-width: 600px) {
            height: auto;
            width: 100%;
        }
        &:hover {
            transform: scale(1.01);
            box-shadow: 0px 24px 24px -14px rgba(249, 198, 35, 0.35);
        }

        &__title {
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            color: #1a2744;
            text-align: left;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        &_img {
            width: 283px;
            height: 233px;
            margin: 80px auto 50px auto;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            img {
                max-width: 100%;
                max-height: 100%;
            }
            @media screen and (max-width: 992px) {
                width: 124px;
                height: 122px;
                margin: 60px auto 43px;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .cards-item {
    }
}

@media screen and (max-width: 1440px) {
    .position {
        padding-right: 45px;
        padding-left: 45px;
    }

    .cards-item {
        padding: 0 20px 20px 20px;
    }

    .cards-item__title {
        font-size: 22px;
        line-height: 32px;
    }
}

@media screen and (max-width: 1024px) {
    .position1 .cards-item_img,
    .position2 .cards-item_img,
    .position3 .cards-item_img,
    .position4 .cards-item_img,
    .position5 .cards-item_img,
    .position6 .cards-item_img {
        max-width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .search-block {
        width: 48%;
    }

    .search-block input {
        width: 100%;
        height: 50px;
    }

    .page-top {
        padding: 0 30px;
    }

    .page-title {
        font-size: 50px;
        line-height: 70px;
    }
    .cards {
        justify-content: space-between;
    }
}

@media screen and (max-width: 992px) {
    .position {
        padding: 20px;
    }
    .page-title {
        font-size: 40px;
        line-height: 50px;
    }
    .wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .header,
    .breadcrumb,
    .page-top {
        display: none;
    }
    .cards-item__title {
        font-size: 18px;
        letter-spacing: -0.13px;
        line-height: 26px;
    }

}


@media screen and (max-width: 540px) {
    .page-title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
    }

    .header {
        padding-top: 40px;
    }

    .cards-item {
        width: 100%;
    }

    .page-top {
        margin-bottom: 15px;
        padding: 0;
    }

    .breadcrumb {
        margin-bottom: 15px;
        padding-left: 15px;
    }

    .search-block {
        width: 100%;
        padding: 0;
    }

    .position {
        padding: 0;
    }

    .search-block input {
        width: 80%;
        height: 50px;
    }
    .page {
        padding: 20px;
    }
}




</style>
